<template>
  <div id="myapp">
    <DemoNavbar></DemoNavbar>
    <div class="mycontainer">
      <div class="row" v-if="!isViewingQuizList">
        <div class="col">
          <div class="card" @click="isViewingQuizList = !isViewingQuizList">
            <div
              class="card_title title-white d-flex flex-column align-items-center"
            >
              <span
                class="iconify my-2"
                data-icon="icons8-student"
                data-inline="false"
                data-width="40"
                data-height="40"
              ></span>
              Quiz
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="isViewingQuizList">
        <div class="col" v-for="(test, index) in tests" :key="index">
          <div class="card" @click="gotoDemoQuiz(index)">
            <div
              class="card_title title-white d-flex flex-column align-items-center"
            >
              <span
                class="iconify my-2"
                data-icon="icons8-student"
                data-inline="false"
                data-width="40"
                data-height="40"
              ></span>
              {{ test.title }}
            </div>
          </div>
        </div>
      </div>

      <div></div>
      <!-- <router-link to="/">Home</router-link> -->
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";

const SmallCard = defineAsyncComponent(() =>
  import("@/components/SmallCard.vue")
);

import store from "@/store";
import db from "@/firebase";
import DemoNavbar from "@/components/DemoNavbar";

export default {
  name: "DemoHome",

  components: {
    DemoNavbar,
    SmallCard
  },
  async mounted() {
    console.log("Here");
    let demoTests = await db.collection("demo-test").get();
    console.log(demoTests.docs.map(doc => doc.id));

    demoTests.docs.forEach(demo => {
      this.tests.push({ ...demo.data(), ...{ id: demo.id } });
    });
    store.state.loading = false;
  },
  data() {
    return {
      tests: [],
      isViewingQuizList: false
    };
  },
  methods: {
    getAddress() {
      return "/demo-quiz";
    },
    gotoDemoQuiz(index) {
      this.$router.push({
        path: this.getAddress(),
        query: {
          id: this.tests[index].id
        }
      });
    }
  }
};
</script>
<style scoped>
#myapp {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%,red);
  background-attachment: fixed;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.card {
  margin: 30px auto;
  width: 260px;
  height: 260px;
  border-radius: 40px;
  box-shadow: 10px 10px 2px rgba(110, 0, 0, 0.25);
  background: #f2e3e3;
  cursor: pointer;
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale();
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 20px;
}

.card .card_title {
  position: absolute;
  text-align: center;
  padding: 5px;
}

.card:hover {
  transform: scale(0.9, 0.9);
  border-radius: 50px;
  box-shadow: 10px 10px 5px 6px rgba(110, 0, 0, 0.25);
}

.title-white {
  color: rgb(148, 22, 0);
  font-size: 20px;
  font-weight: 900;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.title-black {
  color: black;
}

.btn {
  width: 100px;
  margin: auto;
}

a {
  color: black;
}

.notification {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 0;
  background-color: pink;
  opacity: 0.8;
  border-radius: 50%;

  top: 0;
}

.notification > p {
  text-align: center;
  font-weight: bold;
}
</style>
